import Fetch from '@/utils/fetch.js'
import { communityGroupSubject } from '@/api/category/category'

const signUp = (community_group_id, fn, errorFn, dataLevel) => {
  new Fetch(`activity/community_groups/${community_group_id}/records`, 'post', '', fn, errorFn, dataLevel)
}

export {
  communityGroupSubject,
  signUp
}
